import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import { API_BASE_URL } from '@/constants'

const cache = setupCache({ maxAge: 15 * 60 * 1000 }) // 15 minutes

const mainAxios = axios.create({
  baseURL: API_BASE_URL,
  adapter: cache.adapter,
  headers: {
    'Content-Type': 'application/json',
  },
})

const mainCacheAxios = axios.create({
  baseURL: API_BASE_URL,
  adapter: cache.adapter,
  headers: {
    'Content-Type': 'application/json',
  },
})

export {
  mainAxios,
  mainCacheAxios,
}
