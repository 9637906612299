import hexToRgba from 'hex-rgba'
import generateBreakPoints from 'react-jss-grid/utils/breakpoints'

const padding = [0, '80px']
const paddingMobile = [0, '20px']

export const sizes = {
  xs: 575,
  sm: 576,
  md: 720,
  lg: 992,
  xl: 1025,
  xxl: 1440,
}
const media = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
}
const mediaInverse = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (max-width: ${sizes.md - 1}px)`,
  md: `@media (max-width: ${sizes.lg - 1}px)`,
  lg: `@media (max-width: ${sizes.xl - 1}px)`,
  xl: `@media (max-width: ${sizes.xxl - 1}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
  smLand: `@media (max-width: ${sizes.md - 1}px) and (orientation: landscape)`,
}

const colors = [
  '#121212', // #0 Dark
  '#ffffff', // #1 White
  '#000000', // #2 Black
  '#EE472C', // #3 Orange
  '#AAAAAA', // #4 Light Grey
  '#CCCCCC', // #5 Grey
  '#D3D3D3', // #6 Medium Grey
  '#efefef', // #7 Dark White
  '#585858', // #8 Footer Dark Grey
  '#69BC51', // #9 Green
  '#F08E4A', // #10 Orange Selection CSS --old
  '#f06564', // #11 Red Light
]

const categoriesColors = [
  '#AA9A8B', // Hospitality
  '#90A2B2', // Automotive
  '#83908A', // Adventure
]

const fonts = [
  '"Poppins", Helvetica, Arial, sans-serif', // [0]
  '"PoppinsBold", Helvetica, Arial, sans-serif', // [1]
  '"Roboto", Helvetica, Arial, sans-serif', // [2]
]

const easings = {
  'power3.out': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // [0]
  'power3.inOut': 'cubic-bezier(0.645, 0.045, 0.355, 1.000)', // [1]
  'power4.out': 'cubic-bezier(0.76, 0, 0.24, 1)', // [2]
  garret: 'cubic-bezier(0.5, 0, 0, 1);', // [3]
}

const general = {
  fontFamily: fonts[2],
  fontSize: 20,
  lineHeight: 1.3,
  letterSpacing: '0.05em',
  [mediaInverse.sm]: {
    fontSize: 16,
  },
}

const pretitle = {
  fontFamily: fonts[1],
  fontSize: 12,
  lineHeight: 1,
  letterSpacing: '0.15em',
  textTransform: 'uppercase',
}

const zindex = {
  overlay: 101,
  mainNav: 102,
  header: 103,
  cursor: 102,
  modal: 104,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}

const headings = {
  fontFamily: fonts[0],
  fontWeight: 'normal',
  lineHeight: 1.1,
  margin: 0,
  padding: 0,
  color: colors[3],
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  '& strong, & b': {
    fontFamily: fonts[1],
  },
  '& p': {
    margin: 0,
  },
}

const typography = {
  general: {
    extend: general,
  },
  headings: {
    extend: headings,
  },
}

const gutter = {
  lg: 6,
  sm: 4,
}

const wrapper = {
  paddingLeft: '2.3vw',
  paddingRight: '2.3vw',
  maxWidth: 1920,
  margin: [0, 'auto'],
  [mediaInverse.sm]: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}

const frame = {
  paddingLeft: gutter.lg,
  paddingRight: gutter.lg,
  [mediaInverse.sm]: {
    paddingLeft: gutter.sm,
    paddingRight: gutter.sm,
  },
}

const wrapperFrame = {
  paddingLeft: `calc(2.3vw + ${gutter.lg}px)`,
  paddingRight: `calc(2.3vw + ${gutter.lg}px)`,
  [mediaInverse.sm]: {
    paddingLeft: `calc(24px + ${gutter.sm}px)`,
    paddingRight: `calc(24px + ${gutter.sm}px)`,
  },
}

const link = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'currentColor',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: 'scaleX(0)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(1)',
        transformOrigin: '0% 0',
      },
    },
  },
}

const linkInverse = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'currentColor',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: 'scaleX(0)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
      },
    },
  },
}

export const theme = {
  media,
  mediaInverse,
  colors,
  categoriesColors,
  zindex,
  gutter,
  wrapper,
  wrapperFrame,
  frame,
  easings,
  fonts,
  typography,
  pretitle,
  link,
  linkInverse,
  maxWidth: 1600,
  padding,
  paddingMobile,
  header: {
    top: 20,
    bottom: 20,
    logo: {
      width: 255.96,
      height: 35.32,
      fill: colors[3],
    },
    burger: {
      wrap: 45,
      width: 15,
      height: 10,
    },
    topBar: {
      height: 40,
    },
  },
  animations: {
    ease: 'cubic-bezier(0.650, 0.075, 0.400, 0.935)',
    time: '0.6s',
  },
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  breakpoints: generateBreakPoints({
    values: sizes,
  }),
}
